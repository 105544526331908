import React from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';


const Container = styled.div`
  width: 400px;
  padding-left: 38px;
  padding-right: 38px;

  @media (min-width: 768px) { 
    width: 266px;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const Description = styled.div`
  padding-top: 30;
  height: 167;

  @media (min-width: 768px) { 
    padding-top: 20;
    height: 250;
  }
`;

// eslint-disable-next-line react/prop-types
const Project = ({ title, description, tech, renderImage: renderImageProp, imageUrl, link }) => {
  const renderImage = renderImageProp || (() => (
    <div style={{ border: '.3em solid white', borderRadius: '.2em', boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)' }}>
      <img style={{ width: '100%' }} src={imageUrl} alt="" />
    </div>
  ));

  return (
    <Container style={{ textAlign: 'center', overflow: 'hidden', paddingTop: 15 /* Room for shadow */ }}>
      {link
        ? <a href={link} style={{ display: 'block' }} target="_blank" rel="noopener noreferrer">{renderImage()}</a>
        : renderImage()
      }

      <Description style={{ fontSize: 13 }}>
        <span style={{ fontSize: '1.3em', fontWeight: 400 }}>{title}</span><br />
        {description && <span><span style={{ whiteSpace: 'pre-wrap' }}>{description}</span><br /></span>}
        <span style={{ color: 'rgba(0,0,0,0.5)' }}><i className="fa fa-cubes" style={{ fontSize: '80%' }} /> {tech}</span>
      </Description>
    </Container>
  );
};

export default () => (
  <Layout>
    <SEO title="Projects" />

    <div id="wrapper">
      <h1>Projects</h1>

      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'stretch', maxWidth: 1400, margin: 'auto' }}>
          <Project title="WiseGuide App" description="iOS/Android app for Guided meditation." tech="Node.js, React Native, React, AWS" imageUrl="/images/wiseguide.jpg" link="https://www.thewiseguideapp.com" />
          <Project title="FamWeek" description="App and Web platform for kindergardens" tech="React, React Native" imageUrl="/images/famweek.jpeg" link="https://www.famweek.com/" />
          <Project title="Road360" description="Custom 360 video viewer ala Google Street View" tech="Electron, React" imageUrl="/images/presis1.jpg" />
          <Project title="SwimClips" description="Professionally edited swim videos automatically." tech="Node.js, React, AWS" imageUrl="/images/swimclips.jpg" link="https://swimclips.com" />
          <Project title="Editly" description="API to create videos from clips, images, audio and titles with smooth transitions" tech="Node.js, ffmpeg" imageUrl="/images/editly.jpg" link="https://github.com/mifi/editly/" />
          <Project title="LosslessCut" description="Cross platform GUI tool for lossless trimming / cutting of video and audio files using ffmpeg" tech="Node.js, Electron, React" imageUrl="/images/losslesscut.png" link="https://mifi.no/losslesscut/" />
          <Project title="Infopix" description="Digital signage solution with live information (co-founder.)" tech="Node.js, Electron, React, ChromeOS" imageUrl="/images/infopix.png" link="http://infopix.no/" />
          <Project title="SmartControl" description="Platform for internal auditing and inspections of companies." tech="Node.js, React, AWS" imageUrl="/images/smartcontrol.png" link="https://smartcontrol.giantleap.no/" />
          <Project title="DEFA MyCabin App" description="Home automation app for iOS" tech="Objective-C" imageUrl="/images/mycabin.png" link="https://itunes.apple.com/no/app/defa-smartbase-diy/id452232239" />
          <Project title="DEFA Link" description="Web portal for controlling DEFAs products" tech="Java, Groovy, HTML, JavaScript" imageUrl="/images/defalink.png" link="/defalink" />
          <Project title="SimpleInstaBot" description="Easy to use Instagram bot" tech="Electron, React" imageUrl="/images/simpleinstabot.jpg" link="https://mifi.github.io/SimpleInstaBot/" />
          <Project title="PROSITOS" description={'Web-based FIDS\nFor Norwegian airports'} tech="PHP" imageUrl="/images/prositos-s.png" link="/prositos" />
          <Project title="PROSITOS app" description="Mobile web app for flight information" tech="Objective-C, HTML" imageUrl="/images/prositos-app-1.png" link="/prositos-app" />
          <Project title="Photo station" description="Photo kiosk for autmatic creation and printing of student/employee cards" tech="Javscript, NW.js, HTML" imageUrl="/images/photostation.png" />
          <Project title="Epsys.no" description="Company website" tech="PHP" imageUrl="/images/epsys.png" link="http://www.epsys.no/" />
          <Project title="Skiltnett.no" description="Company website" tech="Grails" imageUrl="/images/skiltnett.jpg" link="http://www.skiltnett.no/" />
          <Project title="Realisten.com" description="Community website for science discussions. Acquired by StudentMedia" tech="PHP" imageUrl="/images/realisten.png" link="http://skolediskusjon.no/" />
          <Project title="nRF Cloud" description="Nordic Semiconductors cloud IoT platform. Bluebooth gateway development." tech="React, Redux, Electron, Node.js" imageUrl="/images/nrfcloud.jpg" link="https://nrfcloud.com/" />
          <Project title="Gym access" description="Automated entry solution for fitness center with RFID member card or payment terminal" tech="C#, .NET" imageUrl="/images/fitness.png" />
          <Project title="GateControl" description="Gate entry solution with RFID member cards for venues" tech="Grails, HTML, Android" imageUrl="/images/gatecontrol.png" />
          <Project title="RFID cash card POS" description="Demo for payments using contactless membership cards" tech="Android, HTML" imageUrl="/images/pos-s.png" link="/pos" />
          <Project title="HLS VOD" description="Service for on-demand playback and encoding of local media library to mobile devices (HTTP Live Streaming)" tech="Node.js, express" imageUrl="/images/hls-vod.png" link="https://github.com/mifi/hls-vod" />
          <Project title="Sleekboard" description="On-screen-keyboard for X11/Linux" tech="C, Cairo" imageUrl="/images/sleekboard-s.png" link="https://github.com/mifi/sleekboard" />
          <Project title="SBGR" description="Software implementation of SITA Boarding Gate Reader for airport terminals" tech="C" renderImage={() => <div className="project-img" style={{ background: '#6A6A6A', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}><i className="fa fa-plane" style={{ height: 150, width: 150, fontSize: 150, padding: '70px 0', boxSizing: 'content-box' }} /></div>} />
        </div>
      </div>
    </div>

    <div className="pre-footer" style={{ fontSize: '1.5em', padding: '1.5em', textAlign: 'center', lineHeight: '200%' }}>
      <span style={{ whiteSpace: 'nowrap', paddingRight: '1em' }}><a className="plain" target="_blank" rel="noopener noreferrer" href="https://github.com/mifi/"><i className="fa fa-github" style={{ color: '#d5392d' }} /> GitHub code</a></span>
      <span style={{ whiteSpace: 'nowrap' }}><a className="plain" target="_blank" rel="noopener noreferrer" href="https://blog.mifi.no/"><i className="fa fa-book" style={{ color: '#d5392d' }} /> Blog</a>
      </span>
    </div>
  </Layout>
);
